.alert {
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: none;
  background: #fff;
  padding: 26px 31px;
  border-left: 3px solid;
  margin: 0 auto 20px auto;
  position: relative; }
  .brk-bordered-theme .alert {
    border-radius: var(--b-radius); }
  .alert > .start-icon {
    margin-right: 0;
    min-width: 20px;
    text-align: center; }
    @media screen and (min-width: 576px) {
      .alert > .start-icon {
        margin-right: 25px; }
        [dir="rtl"] .alert > .start-icon {
          margin-right: 0;
          margin-left: 25px; } }
  .alert > a {
    font-weight: 600; }
  .alert .close {
    opacity: 0.5;
    line-height: 26px;
    vertical-align: middle;
    box-shadow: none;
    text-shadow: none; }
    .alert .close:focus, .alert .close:hover {
      color: inherit;
      opacity: 1; }
  .alert.fade {
    will-change: opacity;
    transition: opacity .3s ease-in-out; }
  .alert.show {
    transition: opacity .15s linear; }
  .alert-simple {
    color: #fff; }
    .alert-simple.alert-success {
      border-color: #8cc54f;
      color: #8cc54f; }
    .alert-simple.alert-info {
      border-color: #5151c3;
      color: #5151c3; }
    .alert-simple.alert-warning {
      border-color: #fd9d15;
      color: #fd9d15; }
    .alert-simple.alert-primary {
      border-color: #00c5ff;
      color: #00c5ff; }
    .alert-simple.alert-secondary {
      border-color: #a1a5b5;
      color: #a1a5b5; }
    .alert-simple.alert-light {
      border-color: #dadada;
      color: #929292; }
    .alert-simple.alert-dark {
      background: #515151;
      border-color: #828282;
      color: #fff; }
    .alert-simple.alert-danger {
      border-color: #ef0000;
      color: #ef0000; }
  .alert-clean {
    color: #fff; }
    .alert-clean.alert-success {
      border-color: #8cc54f;
      background: rgba(140, 197, 79, 0.7); }
    .alert-clean.alert-info {
      border-color: #5151c3;
      background: rgba(81, 81, 195, 0.7); }
    .alert-clean.alert-warning {
      border-color: #fd9d15;
      background: rgba(253, 157, 21, 0.7); }
    .alert-clean.alert-danger {
      border-color: #ef0000;
      background: rgba(239, 0, 0, 0.7); }
    .alert-clean.alert-primary {
      border-color: #00c5ff;
      background: rgba(0, 197, 255, 0.7); }
    .alert-clean.alert-secondary {
      border-color: #a1a5b5;
      background: rgba(161, 165, 181, 0.7); }
    .alert-clean.alert-light {
      color: #929292;
      border-color: #dadada;
      background: #fff; }
      .alert-clean.alert-light > .start-icon {
        color: #dadada; }
    .alert-clean.alert-dark {
      border-color: #828282;
      background: rgba(130, 130, 130, 0.7); }
  .alert-rounded {
    border-radius: 22px;
    border-left: none;
    color: #979898; }
    .alert-rounded strong {
      color: #333;
      line-height: 27px; }
    .alert-rounded > .start-icon {
      font-size: 24px; }
      @media screen and (min-width: 576px) {
        .alert-rounded > .start-icon {
          margin-right: 16px; } }
    .alert-rounded.alert-success:before,
    .alert-rounded.alert-success .close {
      color: #8cc54f; }
    .alert-rounded.alert-success .start-icon {
      color: #8cc54f; }
    .alert-rounded.alert-info:before,
    .alert-rounded.alert-info .close {
      color: #1540fa; }
    .alert-rounded.alert-info .start-icon {
      color: var(--brand-primary); }
    .alert-rounded.alert-warning:before,
    .alert-rounded.alert-warning .close {
      color: #fd9d15; }
    .alert-rounded.alert-warning .start-icon {
      color: #fd9d15; }
    .alert-rounded.alert-danger:before,
    .alert-rounded.alert-danger .close {
      color: #ef0000; }
    .alert-rounded.alert-danger .start-icon {
      color: #ef0000; }
    .alert-rounded.alert-primary:before,
    .alert-rounded.alert-primary .close {
      color: #00c5ff; }
    .alert-rounded.alert-primary .start-icon {
      color: #00c5ff; }
    .alert-rounded.alert-secondary:before,
    .alert-rounded.alert-secondary .close {
      color: #a1a5b5; }
    .alert-rounded.alert-secondary .start-icon {
      color: #a1a5b5; }
    .alert-rounded.alert-light:before,
    .alert-rounded.alert-light .close {
      color: #979898; }
    .alert-rounded.alert-light .start-icon {
      color: #272727; }
    .alert-rounded.alert-dark {
      background: #858585; }
      .alert-rounded.alert-dark, .alert-rounded.alert-dark strong {
        color: #fff; }
      .alert-rounded.alert-dark:before,
      .alert-rounded.alert-dark .close {
        color: #515151; }
      .alert-rounded.alert-dark .start-icon {
        color: #fff; }
    .alert-rounded .close span {
      display: inline-block;
      font-size: 0; }
      .alert-rounded .close span i {
        width: 30px;
        height: 30px;
        position: relative; }
        .alert-rounded .close span i:before {
          color: #fff;
          position: relative;
          z-index: 2;
          font-size: 14px;
          line-height: 30px; }
        .alert-rounded .close span i:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 15px solid;
          border-radius: 50%; }
    .alert-rounded:before {
      content: '';
      position: absolute;
      width: 0;
      height: calc(100% - 44px);
      border-left: 1px solid;
      border-right: 2px solid;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%); }

body .alert-simple.alert-brk {
  border-color: #6c3ba9;
  color: #6c3ba9; }

body .alert-clean.alert-brk {
  border-color: #6c3ba9;
  background: #a07ad0; }

body .alert-rounded.alert-brk {
  background: #fff; }
  body .alert-rounded.alert-brk, body .alert-rounded.alert-brk strong {
    color: #6c3ba9; }
  body .alert-rounded.alert-brk:before,
  body .alert-rounded.alert-brk .close {
    color: #6c3ba9; }
  body .alert-rounded.alert-brk .start-icon {
    color: #8554c3; }
